// phpcs:ignoreFile

//
//  Variables
//  ______________________________________________

//
//  Colors
//  ----------------------------------------------

$amsearch-item__background__hover: #f4f5fa;
$amsearch-product-sought__background: #3ac39c;
$amsearch-border__color: #ccc;
$amsearch-secondary__color: #1979c2;

$amsearch-icon__background__compare: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgMTRWOS4zNjk3OUgwVjE0SDVaTTEyLjQ2MDkgMEg3LjVWMTRIMTIuNDYwOVYwWk0yMCA0LjcwMzEySDE1VjE0SDIwVjQuNzAzMTJaIiBmaWxsPSIjNkU3MTZFIi8+Cjwvc3ZnPgo=);
$amsearch-icon__background__wishlist: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAyMCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjA2MTIgMC40NzczMUMxNS43NjIgLTAuMTAzNzYzIDE0LjQ0OTcgLTAuMTU0MjkxIDEzLjEyNDUgMC4zMjU3MjZDMTEuNzk5MiAwLjc4MDQ3OSAxMC44Mzc3IDEuNjM5NDYgMTAuMjQwMSAyLjkwMjY2QzEwLjE4ODEgMy4wMDM3MiAxMC4xMzYxIDMuMTA0NzcgMTAuMDg0MiAzLjIwNTgzQzEwLjA1ODIgMy4zMDY4OCAxMC4wMzIyIDMuNDIwNTcgMTAuMDA2MiAzLjU0Njg5QzkuOTgwMjMgMy40MjA1NyA5Ljk0MTI1IDMuMzA2ODggOS44ODkyOCAzLjIwNTgzQzkuODYzMyAzLjEwNDc3IDkuODI0MzIgMy4wMDM3MiA5Ljc3MjM1IDIuOTAyNjZDOS4xNzQ2OCAxLjYzOTQ2IDguMjEzMjMgMC43ODA0NzkgNi44ODc5NyAwLjMyNTcyNkM1LjU2MjcyIC0wLjE1NDI5MSA0LjI1MDQ2IC0wLjEwMzc2MyAyLjk1MTE5IDAuNDc3MzFDMS42Nzc5MSAxLjA4MzY1IDAuNzk0NDA5IDIuMDQzNjggMC4zMDA2ODggMy4zNTc0MUMtMC4xNjcwNDkgNC42NzExNCAtMC4wODkwOTI2IDUuOTU5NjEgMC41MzQ1NTYgNy4yMjI4MUMwLjgyMDM5NSA3Ljg1NDQxIDEuNDcwMDMgOC43MjYwMiAyLjQ4MzQ2IDkuODM3NjRDMy41MjI4NyAxMC45MjQgNC42MDEyNiAxMS45OTc3IDUuNzE4NjMgMTMuMDU4OEM2LjgzNiAxNC4xMTk5IDcuODIzNDUgMTUuMDQyIDguNjgwOTYgMTUuODI1MkM5LjU2NDQ2IDE2LjYwODQgMTAuMDA2MiAxNyAxMC4wMDYyIDE3QzEwLjAwNjIgMTcgMTAuMDA2MiAxNi45ODc0IDEwLjAwNjIgMTYuOTYyMUMxMC4wMDYyIDE2Ljk4NzQgMTAuMDA2MiAxNyAxMC4wMDYyIDE3QzEwLjAwNjIgMTcgMTAuNDM1IDE2LjYwODQgMTEuMjkyNSAxNS44MjUyQzEyLjE3NiAxNS4wNDIgMTMuMTc2NCAxNC4xMTk5IDE0LjI5MzggMTMuMDU4OEMxNS40MTEyIDExLjk5NzcgMTYuNDc2NiAxMC45MjQgMTcuNDkgOS44Mzc2NEMxOC41Mjk0IDguNzI2MDIgMTkuMTkyIDcuODU0NDEgMTkuNDc3OSA3LjIyMjgxQzIwLjEwMTUgNS45NTk2MSAyMC4xNjY1IDQuNjcxMTQgMTkuNjcyOCAzLjM1NzQxQzE5LjIwNSAyLjA0MzY4IDE4LjMzNDUgMS4wODM2NSAxNy4wNjEyIDAuNDc3MzFaIiBmaWxsPSIjNkU3MTZFIi8+Cjwvc3ZnPgo=);
$amsearch-icon__background__close: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMWwxMCAxMG0wLTEwTDEgMTEiIHN0cm9rZT0iI0IyQjJCMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==);
$amsearch-icon__background__loupe: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1Ni45NjYgNTYuOTY2Ij48cGF0aCBkPSJNNTUuMTQ2IDUxLjg4N0w0MS41ODggMzcuNzg2QTIyLjkyNiAyMi45MjYgMCAwIDAgNDYuOTg0IDIzYzAtMTIuNjgyLTEwLjMxOC0yMy0yMy0yM3MtMjMgMTAuMzE4LTIzIDIzIDEwLjMxOCAyMyAyMyAyM2M0Ljc2MSAwIDkuMjk4LTEuNDM2IDEzLjE3Ny00LjE2MmwxMy42NjEgMTQuMjA4Yy41NzEuNTkzIDEuMzM5LjkyIDIuMTYyLjkyLjc3OSAwIDEuNTE4LS4yOTcgMi4wNzktLjgzN2EzLjAwNCAzLjAwNCAwIDAgMCAuMDgzLTQuMjQyek0yMy45ODQgNmM5LjM3NCAwIDE3IDcuNjI2IDE3IDE3cy03LjYyNiAxNy0xNyAxNy0xNy03LjYyNi0xNy0xNyA3LjYyNi0xNyAxNy0xN3oiIGZpbGw9IiNmM2E3MTIiLz48L3N2Zz4=);
$amsearch-icon__background__loader: url(data:image/gif;base64,R0lGODlhEAALAPQAAP///wAAANra2tDQ0Orq6gYGBgAAAC4uLoKCgmBgYLq6uiIiIkpKSoqKimRkZL6+viYmJgQEBE5OTubm5tjY2PT09Dg4ONzc3PLy8ra2tqCgoMrKyu7u7gAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCwAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQJCwAAACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQJCwAAACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQJCwAAACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAkLAAAALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkECQsAAAAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAkLAAAALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkECQsAAAAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA);
$amsearch-icon__background__arrow-right: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNDUxLjg0NiA0NTEuODQ3Ij48cGF0aCBkPSJNMzQ1LjQ0MSAyNDguMjkyTDE1MS4xNTQgNDQyLjU3M2MtMTIuMzU5IDEyLjM2NS0zMi4zOTcgMTIuMzY1LTQ0Ljc1IDAtMTIuMzU0LTEyLjM1NC0xMi4zNTQtMzIuMzkxIDAtNDQuNzQ0TDI3OC4zMTggMjI1LjkyIDEwNi40MDkgNTQuMDE3Yy0xMi4zNTQtMTIuMzU5LTEyLjM1NC0zMi4zOTQgMC00NC43NDggMTIuMzU0LTEyLjM1OSAzMi4zOTEtMTIuMzU5IDQ0Ljc1IDBsMTk0LjI4NyAxOTQuMjg0YzYuMTc3IDYuMTggOS4yNjIgMTQuMjcxIDkuMjYyIDIyLjM2NiAwIDguMDk5LTMuMDkxIDE2LjE5Ni05LjI2NyAyMi4zNzN6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIGRhdGEtb2xkX2NvbG9yPSIjYTNhM2EzIiBmaWxsPSIjYTNhM2EzIi8+PC9zdmc+) no-repeat center;

//
//  Indents
//  ----------------------------------------------

$amsearch__indent: 10px;
$amsearch__indent__m: $amsearch__indent * 2 - $amsearch__indent / 2;
$amsearch__indent__l: $amsearch__indent * 2;
$amsearch__indent__xl: $amsearch__indent + $amsearch__indent__m;

$amsearch-slider-arrow__size: 40px;
$amsearch-sidebar__width: 283px;
