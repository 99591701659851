//
//  Search Page Styles
//  ______________________________________________

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .amsearch-search-page.page-products .columns {
        z-index: 0;
    }
}


.tooltip.amshopby-filter-tooltip {
    display: none;
}
