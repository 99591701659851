//
//  Mixins
//  _____________________________________

@mixin am-text-clamp(
    $font-size: 14,
    $lines: 2
) {
    $height: unit(round($font-size * ($font-size / 10) * $lines), px);

    display: block;
    font-size: unit($font-size, px);
    line-height: $font-size / 10;
    max-height: $height;
    min-height: $height;
    overflow: hidden;
}

@mixin am-word-break {
    & {
        word-break: break-word;
        word-wrap: break-word;
    }

    .ie11 & {
        word-break: break-all;
    }
}
