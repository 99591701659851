//
//  Search Input Styles
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

.amsearch-wrapper-inner {
    .amstockstatus-category {
        display: none !important;
    }
}

.page-header .amsearch-input-wrapper {
    width: 100%;
    height: 0;
}

.amsearch-input-wrapper {
    & {
        max-width: 100%;
        position: relative;
        transition: 0.3s;
    }

    &.-typed {
        .amsearch-input {
            padding-left: 33px;
            padding-right: 33px;
        }
    }

    .amsearch-input {
        & {

            padding: 5px 10px;
            transition: 0.3s;
            top: -20px;
            position: absolute;
        }

        &:focus {
            & {
                border: 1px solid $amsearch-secondary__color;
                box-shadow: none;
            }

            &::placeholder,
            &::-webkit-input-placeholder {
                color: transparent;
            }
        }

        &::placeholder,
        &::-webkit-input-placeholder {
            color: #999;
        }
    }

    .amsearch-button {
        &.-close {
            bottom: 0;
            font-size: 1rem;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
        }

        &.-loupe {
            border: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            right: 5px;
            top: 0;
            display: block;
            background-size: 20px;
            opacity: 1;
        }
    }
}

//
//  Mobile
//  ---------------------------------------------

@include min-screen($screen__m) {
    .page-header:not(.amsearch-full-width) .amsearch-input-wrapper.-dynamic-width {
        width: 100%;
    }

    .page-header .amsearch-input-wrapper {
        min-width: 283px;
        width: auto;
        height: unset;

        .amsearch-input {
            & {

                padding: 5px 10px;
                transition: 0.3s;
                top: -20px;
                position: unset;
            }
        }
    }
}
