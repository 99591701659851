//
//  Search Type Full Width Styles
//  ______________________________________________

//
//  Variables
//  ----------------------------------------------

$amsearch-full-width-wrapper__padding: 15px;

//
//  Common
//  ----------------------------------------------

.amsearch-button.-full-close {
    display: none;
}

.page-header {
    .amsearch-button.-loupe-trigger {
        background-size: 20px;
        filter: brightness(75%);
        float: right;
        height: 20px;
        margin: 14px;
        width: 20px;
    }
}

.page-header.amsearch-full-width {
    .header.content {
        transition: none;
    }

    .amsearch-full_width-title {
        flex-basis: 100%;
        font-size: 2.6rem;
        font-weight: 300;
        margin: 0;
        padding: 0 0 $amsearch__indent__l 0;
        z-index: 15;
    }

    .amsearch-input-wrapper {
        flex-grow: 10;
        width: initial;
    }

    .amsearch-button.-search {
        flex-grow: 1;
    }

    .amsearch-wrapper-block {
        display: none;
    }

    .amsearch-form-block {
        flex-wrap: wrap;
        height: auto;
        justify-content: flex-start;
        min-width: 100%;
        padding: $amsearch__indent__l $amsearch-full-width-wrapper__padding;
        transition: none;
    }

    .amsearch-button.-full-close {
        bottom: 0;
        margin: $amsearch__indent__l;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 98;
    }

    .amsearch-result-section {
        border-radius: 0;
        border-top: 1px solid $amsearch-border__color;
        margin: 0;
        transition: none;
        width: 100%;
    }

    .amsearch-slider-section {
        max-width: none;
        width: 100%;
    }

    .amsearch-wrapper-block {
        background: #f5f5f5;
        height: 100%;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 14;
    }
}

.page-header.amsearch-full-width.-opened {
    & {
        height: 134px;
        transition: all 1s ease;
    }

    .panel.wrapper {
        display: none;
    }

    .header.content {
        align-content: center;
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        max-width: 100%;
        padding: 0;
    }

    .minicart-wrapper {
        display: none;
    }

    .amsearch-wrapper-block {
        display: inline-block;
    }

    .amsearch-button.-full-close {
        display: inline-flex;
    }
}

//
//  Mobile
//  --------------------------------------------

@include min-screen($screen__m) {

    .amsearch-full_width-title {
        display: none;
    }

    .page-header.amsearch-full-width.-opened {
        .ammenu-logo,
        .logo {
            margin: 0 auto 0 $amsearch-full-width-wrapper__padding;
            z-index: 11;
        }

        .amsearch-wrapper-block {
            z-index: 10;
        }

        .amsearch-button.-full-close {
            right: $amsearch-full-width-wrapper__padding;
        }
    }

    .page-header.amsearch-full-width {
        .amsearch-input-wrapper {
            flex-grow: initial;
        }

        .amsearch-button.-search {
            flex-grow: initial;
        }

        .amsearch-wrapper-block {
            background: #fff;
            display: inline-block;
        }

        &:not(.-opened) .amsearch-wrapper-block {
            position: static;
            width: auto;
        }

        .amsearch-form-block {
            height: 100%;
            justify-content: center;
            padding: 0;
        }

        .amsearch-result-section {
            padding: 0 calc($amsearch-full-width-wrapper__padding - 15px);
        }

        .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
            max-width: calc(100% / 4);
        }

        .amsearch-products-section.-list .amsearch-item {
            width: 50%;
        }

        .amsearch-button.-full-close {
            margin: auto;
        }
    }

    .page-header .amsearch-button.-loupe-trigger {
        display: none;
    }
}

//
//  Desktop
//  --------------------------------------------

@include min-screen($screen__l) {
    .page-header.amsearch-full-width .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
        max-width: calc(100% / 5);
    }
}
